<template>
  <div class="">
    <div
      class="container grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
      v-if="products.length > 0"
    >
      <ProductItem2
        v-for="product in products"
        :product="product"
        :isMatrix="isMatrix"
        :isPresale="isPresale"
        v-on:addToCart="openOptions"
        v-on:showProductDetails="openProductDetails"
        :key="product._id"
      />

      <sui-modal v-model="open" size="tiny">
        <sui-modal-header>Agregar Producto</sui-modal-header>
        <sui-modal-content image>
          <sui-image wrapped size="small" :src="productImage" />
          <sui-modal-description>
            <sui-header>{{ tmpProduct.name }}</sui-header>
            <div
              v-if="tmpProduct.type === 'DIGITAL'"
              class="ui segment"
              style="font-size: 10px; text-align: justify"
            >
              {{ tmpProduct.description }}
            </div>

            <div class="ui form many-items" :class="{ loading: isLoading }">
              <table class="ui very basic compact table unstackable">
                <thead>
                  <tr>
                    <th class="one wide" scope="col">
                      {{ tmpProduct.type === "DIGITAL" ? "Boleto" : "Talla" }}
                    </th>
                    <th class="one wide" scope="col" v-if="showStocks">Existencia</th>
                    <th class="one wide" scope="col">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="stock in tmpProduct.stocks" :key="stock._id">
                    <td class="center aligned done wide">
                      {{ stock.attribute }}
                    </td>
                    <td class="center aligned one wide" v-if="showStocks">
                      {{ stock.stock }}
                    </td>
                    <td class="two wide">
                      <vue-numeric-input
                        v-model="stock.quantity"
                        :min="0"
                        :max="stock.stock > 0 ? stock.stock : 10"
                        :step="1"
                        :precision="0"
                        size="100px"
                        align="center"
                      ></vue-numeric-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </sui-modal-description>
        </sui-modal-content>
        <sui-modal-actions>
          <button class="ui gray button" @click="closeOptions">Cancelar</button>
          <button
            class="ui red button"
            :class="{ loading: isLoading, disabled: isLoading }"
            @click="addProductToCart"
          >
            &nbsp;&nbsp;Añadir&nbsp;&nbsp;
          </button>
        </sui-modal-actions>
      </sui-modal>
    </div>
    <div v-else class="">
      <h3 class="ui center aligned header">
        No hay productos para mostrar en esta categoría o búsqueda
      </h3>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProductItem2 from "./ProductItem2";
import VueNumericInput from "vue-numeric-input";
import MetricsService, { EventKey } from "@/services/metrics";

export default {
  name: "ProductList",
  props: ["isMatrix", "isPresale"],
  created() {},
  computed: {
    products() {
      return this.$store.getters["products/getProducts"];
    },
    productImage: function () {
      return this.tmpProduct.images.length > 0
        ? this.tmpProduct.images[0].thumbnailUrl
        : this.tmpProduct.image;
    },
    presale: function () {
      return this.$store.getters["auth/getPresale"];
    },
  },
  methods: {
    closeOptions: function () {
      this.open = !this.open;
    },
    openProductDetails: function (productId) {
      this.$router.push({
        name: "ProductDetails",
        params: { productId: productId },
      });
    },
    openOptions: async function (productId, price, wholesalePrice, hasStock) {
      this.showStocks = hasStock;
      const cart = this.$store.getters["cart/getCart"];

      if (cart.products.length === 0 || !!cart.isPresale === this.isPresale) {
        const product = await this.$store.dispatch("products/productById", productId);

        this.tmpProduct = _.cloneDeep(product);

        this.tmpProduct.shopPrice = price;
        this.tmpProduct.shopWholesalePrice = wholesalePrice;

        this.tmpProduct.stocks = this.tmpProduct.stocks
          .filter(
            (s) =>
              (s.stock > 0 || this.isPresale) &&
              !cart.products.find((p) => p._id === s._id)
          )
          .map((s) => {
            if (s.stock > 0 || this.isPresale) return { ...s, quantity: 0 };
          });
        this.open = !this.open;
      } else {
        alert(
          "No puedes agregar productos normales y productos en Preventa en el mismo pedido"
        );
      }
    },
    addProductToCart: async function () {
      //map product into cart items
      if (this.isLoading) return;

      const products = this.tmpProduct.stocks
        .filter((s) => s.quantity > 0)
        .map((s) => {
          const name =
            this.tmpProduct.name +
            " - " +
            this.tmpProduct.brands[0].name +
            " - " +
            (this.tmpProduct.color
              ? this.tmpProduct.color + " - T: "
              : this.tmpProduct.type === "DIGITAL"
              ? "Boleto: "
              : "T: ") +
            s.attribute;
          return {
            productId: this.tmpProduct._id,
            brandId: this.tmpProduct.brands[0]._id,
            attributeId: s._id,
            name: name,
            price: this.tmpProduct.price,
            wholesalePrice: this.tmpProduct.wholesalePrice,
            shopPrice: this.tmpProduct.shopPrice,
            shopWholesalePrice: this.tmpProduct.shopWholesalePrice,
            quantity: s.quantity,
            image:
              this.tmpProduct.images.length > 0
                ? this.tmpProduct.images[0].thumbnailUrl
                : this.tmpProduct.image,
            extended1: this.tmpProduct.extended1,
            extended2: this.tmpProduct.extended2,
          };
        });

      if (products && products.length > 0) {
        const productData = this.isPresale
          ? {
              products: products,
              isPresale: this.isPresale,
              presale: this.presale,
            }
          : {
              products: products,
              isPresale: this.isPresale,
            };
        try {
          this.isLoading = true;
          await this.$store.dispatch("cart/addProduct", productData);
        } catch (err) {
          console.log("Error adding product to cart", err);
        } finally {
          this.isLoading = false;
        }
      }

      this.open = !this.open;
    },
  },
  data() {
    return {
      open: false,
      isLoading: false,
      tmpProduct: {
        name: "",
        brands: [],
        image: "",
        stocks: [],
        images: [],
      },
      showStocks: true,
    };
  },
  components: {
    ProductItem2,
    VueNumericInput,
  },
};
</script>
<style>
.products {
  background: #f7f8fb;
  padding: 30px 0;
}

.many-items {
  max-height: 400px;

  overflow-y: scroll;
  border: 2px inset rgba(110, 110, 110, 0.1);
  border-radius: 6px;
  padding: 20px;
}

.fixed_header thead tr {
  display: block;
}

@media only screen and (max-width: 800px) {
  .many-items {
    max-height: 280px;
    padding: 12px;
    overflow-y: scroll;
    border: 2px inset rgba(110, 110, 110, 0.27);
    border-radius: 6px;
  }
}
</style>
